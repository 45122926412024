
import { Vue } from "nuxt-property-decorator"

export default Vue.extend({
  name: "HeaderCards",
  methods: {
    trackCardClick(title: string) {
      this.$snowplow.trackCategoryCardClick(title)
    },
    getAbsoluteUrl(path: string) {
      return `${this.$config.v4Host.replace("com", this.$i18n.locale)}/${path}`
    }
  }
})
