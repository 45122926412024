export const LISTING_STATUS_PRE = "pre"
export const LISTING_STATUS_PENDING = "pending"
export const LISTING_STATUS_COMPLETED = "completed"
export const LISTING_STATUS_CANCELLED = "cancelled"
export const LISTING_STATUS_ABANDONED = "abandoned"
export const LISTING_STATUS_ALLOCATED = "allocated"
export const LISTING_STATUS_ALLOCATED_JOURNEY = "allocated_journey"
export const LISTING_STATUS_ALLOCATED_INDEPENDENT = "allocated_independent"
export const LISTING_STATUS_CLICK_AND_CLAIM = "click_and_claim"
export const LISTING_STATUS_EXPIRED = "expired"
export const LISTING_STATUS_EXPRESS_INTEREST_COMPLETE = "express_interest_complete"
export const LISTING_STATUS_EXPRESS_INTEREST_PENDING = "express_interest_pending"
export const LISTING_STATUS_EXPRESS_INTEREST_PROCESSING = "express_interest_processing"
export const LISTING_STATUS_FINALISED = "finalised"
export const LISTING_STATUS_IN_REALISATION = "in_realisation"
export const LISTING_STATUS_JOURNEY_PENDING = "journey_pending"
export const LISTING_STATUS_JOURNEY_PLACED = "journey_placed"
export const LISTING_STATUS_REALISED = "realised"
export const LISTING_STATUS_SCREENING_ROOM = "screening_room"
export const LISTING_STATUS_UNDER_ANALYSIS = "under_analysis"

export const DATE_TYPE_FIXED = "fixed"
export const DATE_TYPE_FLEXIBLE = "flexible"
export const DATE_TYPE_HOURLY = "hourly"

export const FLOOR_BASEMENT = "Basement"
export const FLOOR_GROUND = "Ground floor"
export const FLOOR_1ST = "1st floor"
export const FLOOR_2ND = "2nd floor"
export const FLOOR_3RD = "3rd floor"
export const FLOOR_4TH = "4th floor"
export const FLOOR_5TH = "5th floor"
export const FLOOR_6TH = "6th floor"
export const FLOOR_ABOVE_6TH = "Above 6th floor"

export const V2_FLOOR_ABOVE_3RD = "Above 3rd floor"

export const LIFT_AVAILABLE = 1
export const LIFT_UNAVAILABLE = 0

export const V2_FLOORS = {
  [FLOOR_BASEMENT]: FLOOR_BASEMENT,
  [FLOOR_GROUND]: FLOOR_GROUND,
  [FLOOR_1ST]: FLOOR_1ST,
  [FLOOR_2ND]: FLOOR_2ND,
  [FLOOR_3RD]: FLOOR_3RD,
  [FLOOR_4TH]: V2_FLOOR_ABOVE_3RD,
  [FLOOR_5TH]: V2_FLOOR_ABOVE_3RD,
  [FLOOR_6TH]: V2_FLOOR_ABOVE_3RD,
  [FLOOR_ABOVE_6TH]: V2_FLOOR_ABOVE_3RD
}

export const LISTING_REALISED_STATUSES = [
  LISTING_STATUS_COMPLETED,
  LISTING_STATUS_ABANDONED,
  LISTING_STATUS_EXPIRED,
  LISTING_STATUS_EXPRESS_INTEREST_COMPLETE,
  LISTING_STATUS_FINALISED,
  LISTING_STATUS_IN_REALISATION,
  LISTING_STATUS_REALISED
]

export const FLOORS: OptionString[] = [
  {
    name: FLOOR_BASEMENT,
    value: FLOOR_BASEMENT
  },
  {
    name: FLOOR_GROUND,
    value: FLOOR_GROUND
  },
  {
    name: FLOOR_1ST,
    value: FLOOR_1ST
  },
  {
    name: FLOOR_2ND,
    value: FLOOR_2ND
  },
  {
    name: FLOOR_3RD,
    value: FLOOR_3RD
  },
  {
    name: FLOOR_4TH,
    value: FLOOR_4TH
  },
  {
    name: FLOOR_5TH,
    value: FLOOR_5TH
  },
  {
    name: FLOOR_6TH,
    value: FLOOR_6TH
  },
  {
    name: FLOOR_ABOVE_6TH,
    value: FLOOR_ABOVE_6TH
  }
]

export const HOUSE = "House"
export const FLAT = "Flat"
export const ONE_BED_HOUSE = "1 Bed House"
export const TWO_BED_HOUSE = "2 Bed House"
export const THREE_BED_HOUSE = "3 Bed House"
export const FOUR_BED_HOUSE = "4+ Bed House"
export const ONE_BED_FLAT = "1 Bed Flat"
export const TWO_BED_FLAT = "2 Bed Flat"
export const THREE_BED_FLAT = "3 Bed Flat"
export const FOUR_BED_FLAT = "4+ Bed Flat"
export const STUDIO = "Studio"
export const STORAGE = "Storage"
export const FLATSHARE = "Flatshare"

export const V2_HOUSE = "House"
export const V2_FLAT = "Flat"
export const V2_STORAGE = "Storage"
export const V2_FLATSHARE = "Room"

export const V2_PROPERTY_TYPES = {
  [ONE_BED_HOUSE]: V2_HOUSE,
  [TWO_BED_HOUSE]: V2_HOUSE,
  [THREE_BED_HOUSE]: V2_HOUSE,
  [FOUR_BED_HOUSE]: V2_HOUSE,
  [ONE_BED_FLAT]: V2_FLAT,
  [TWO_BED_FLAT]: V2_FLAT,
  [THREE_BED_FLAT]: V2_FLAT,
  [FOUR_BED_FLAT]: V2_FLAT,
  [STUDIO]: V2_FLATSHARE,
  [STORAGE]: V2_STORAGE,
  [FLATSHARE]: V2_FLATSHARE
}

export const PROPERTY_TYPE_LIST_MAIN: OptionString[] = [
  {
    name: "House",
    value: HOUSE
  },
  {
    name: "Flat",
    value: FLAT
  },
  {
    name: "Studio",
    value: STUDIO
  },
  {
    name: "Storage Unit",
    value: STORAGE
  },
  {
    name: "Flatshare",
    value: FLATSHARE
  }
]

export const PROPERTY_TYPE_LIST_HOUSE: OptionString[] = [
  {
    name: "1 bed house",
    value: ONE_BED_HOUSE
  },
  {
    name: "2 bed house",
    value: TWO_BED_HOUSE
  },
  {
    name: "3 bed house",
    value: THREE_BED_HOUSE
  },
  {
    name: "4+ bed house",
    value: FOUR_BED_HOUSE
  }
]

export const PROPERTY_TYPE_LIST_WITH_FLOOR: OptionString[] = [
  {
    name: "1 bed flat",
    value: ONE_BED_FLAT
  },
  {
    name: "2 bed flat",
    value: TWO_BED_FLAT
  },
  {
    name: "3 bed flat",
    value: THREE_BED_FLAT
  },
  {
    name: "4+ bed flat",
    value: FOUR_BED_FLAT
  },
  {
    name: "Studio",
    value: STUDIO
  },
  {
    name: "Flatshare",
    value: FLATSHARE
  }
]

export const PROPERTY_TYPE_LIST_FLAT: OptionString[] = [
  {
    name: "1 bed flat",
    value: ONE_BED_FLAT
  },
  {
    name: "2 bed flat",
    value: TWO_BED_FLAT
  },
  {
    name: "3 bed flat",
    value: THREE_BED_FLAT
  },
  {
    name: "4+ bed flat",
    value: FOUR_BED_FLAT
  }
]

export const PROPERTY_TYPE_LIST: OptionString[] = [
  {
    name: "1 bed house",
    value: ONE_BED_HOUSE
  },
  {
    name: "2 bed house",
    value: TWO_BED_HOUSE
  },
  {
    name: "3 bed house",
    value: THREE_BED_HOUSE
  },
  {
    name: "4+ bed house",
    value: FOUR_BED_HOUSE
  },
  {
    name: "1 bed flat",
    value: ONE_BED_FLAT
  },
  {
    name: "2 bed flat",
    value: TWO_BED_FLAT
  },
  {
    name: "3 bed flat",
    value: THREE_BED_FLAT
  },
  {
    name: "4+ bed flat",
    value: FOUR_BED_FLAT
  },
  {
    name: "Studio",
    value: STUDIO
  },
  {
    name: "Storage Unit",
    value: STORAGE
  },
  {
    name: "Flatshare",
    value: FLATSHARE
  }
]

export const PROPERTY_TYPES: any[] = [
  {
    label: "House",
    icon: ["custom", "building-hotel"],
    values: [
      { name: "1 bed house", value: ONE_BED_HOUSE },
      { name: "2 bed house", value: TWO_BED_HOUSE },
      { name: "3 bed house", value: THREE_BED_HOUSE },
      { name: "4+ bed house", value: FOUR_BED_HOUSE }
    ]
  },
  {
    label: "Flat",
    icon: ["custom", "building-1-1"],
    values: [
      { name: "1 bed flat", value: ONE_BED_FLAT },
      { name: "2 bed flat", value: TWO_BED_FLAT },
      { name: "3 bed flat", value: THREE_BED_FLAT },
      { name: "4+ bed flat", value: FOUR_BED_FLAT }
    ]
  },
  {
    label: "Other",
    icon: ["custom", "building-5"],
    values: [
      { name: "Studio", value: STUDIO },
      { name: "Storage Unit", value: STORAGE },
      { name: "Flatshare", value: FLATSHARE }
    ]
  }
]

export const PROPERTIES_WITH_LEVELS: string[] = [
  ONE_BED_FLAT,
  TWO_BED_FLAT,
  THREE_BED_FLAT,
  FOUR_BED_FLAT,
  STUDIO,
  FLATSHARE
]

// this will come from backend
export const PEOPLE: OptionNumber[] = [
  { name: "1 person", value: 1 },
  { name: "2 people", value: 2 },
  { name: "3 people", value: 3 }
]

export const TIME_OPTIONS: OptionNumber[] = [
  { name: "6am", value: 6 },
  { name: "7am", value: 7 },
  { name: "8am", value: 8 },
  { name: "9am", value: 9 },
  { name: "10am", value: 10 },
  { name: "11am", value: 11 },
  { name: "12pm", value: 12 },
  { name: "1pm", value: 13 },
  { name: "2pm", value: 14 },
  { name: "3pm", value: 15 },
  { name: "4pm", value: 16 },
  { name: "5pm", value: 17 },
  { name: "6pm", value: 18 },
  { name: "7pm", value: 19 },
  { name: "8pm", value: 20 }
]

export const DEFAULT_MIN_HOUR = 8
export const DEFAULT_MAX_HOUR = 18

export const DEFAULT_TIME_FROM = "08:00:00"
export const DEFAULT_TIME_TO = "18:00:00"

// 8 is hardcoded, max hours what we get prices for
// from the backend and above we should allow always
export const MAX_HOUR_SLIDER_PRICES = 8

export const STATUSES: Object = {
  [LISTING_STATUS_PRE]: {
    icon: ["custom", "shipping-truck", "scale(-1, 1)"],
    customerCopy: "Quote",
    tpCopy: "Quote"
  },
  [LISTING_STATUS_PENDING]: {
    icon: ["custom", "shipping-truck", "scale(-1, 1)"],
    customerCopy: "Quote",
    tpCopy: "Pending"
  },
  [LISTING_STATUS_EXPIRED]: {
    icon: ["custom", "shipping-truck", "scale(-1, 1)"],
    customerCopy: "Quote",
    tpCopy: ""
  },
  [LISTING_STATUS_SCREENING_ROOM]: {
    icon: ["custom", "shipping-truck", "scale(-1, 1)"],
    customerCopy: "Job Booked",
    tpCopy: ""
  },
  [LISTING_STATUS_COMPLETED]: {
    icon: ["fas", "check"],
    customerCopy: "Job Complete",
    tpCopy: "Job Complete"
  },
  [LISTING_STATUS_CANCELLED]: {
    icon: ["custom", "shipping-truck", "scale(-1, 1)"],
    customerCopy: "Job Cancelled",
    tpCopy: "Cancelled"
  },
  [LISTING_STATUS_UNDER_ANALYSIS]: {
    icon: ["custom", "search-find"],
    customerCopy: "Job Booked",
    tpCopy: "Under Analysis"
  },
  [LISTING_STATUS_ABANDONED]: {
    icon: ["custom", "remove-1", "scale(1.2, 1.2)", "#f74f64"],
    customerCopy: {
      customer: "Expired",
      tp: "Completed"
    },
    tpCopy: "Abandoned"
  },
  [LISTING_STATUS_CLICK_AND_CLAIM]: {
    icon: ["custom", "shipping-truck", "scale(-1, 1)"],
    customerCopy: "Job Booked",
    tpCopy: "Click & Claim"
  },
  [LISTING_STATUS_ALLOCATED]: {
    icon: ["custom", "shipping-truck", "scale(-1, 1)"],
    customerCopy: "Allocated",
    tpCopy: "Allocated"
  },
  [LISTING_STATUS_IN_REALISATION]: {
    icon: ["custom", "shipping-truck", "scale(-1, 1)"],
    customerCopy: "Driver On Way",
    tpCopy: "In Realisation"
  },
  [LISTING_STATUS_REALISED]: {
    icon: ["custom", "shipping-truck", "scale(-1, 1)"],
    customerCopy: "Job Complete",
    tpCopy: "Realised"
  },
  [LISTING_STATUS_JOURNEY_PENDING]: {
    icon: ["custom", "shipping-truck", "scale(-1, 1)"],
    customerCopy: "Job Booked",
    tpCopy: "Journey Pending"
  },
  [LISTING_STATUS_JOURNEY_PLACED]: {
    icon: ["custom", "shipping-truck", "scale(-1, 1)"],
    customerCopy: "Job Booked",
    tpCopy: "Journey Placed"
  },
  [LISTING_STATUS_ALLOCATED_JOURNEY]: {
    icon: ["custom", "shipping-truck", "scale(-1, 1)"],
    customerCopy: "Job Booked",
    tpCopy: "Allocated"
  },
  [LISTING_STATUS_ALLOCATED_INDEPENDENT]: {
    icon: ["custom", "shipping-truck", "scale(-1, 1)"],
    customerCopy: "Job Booked",
    tpCopy: "Allocated"
  },
  [LISTING_STATUS_FINALISED]: {
    icon: ["fas", "check"],
    customerCopy: "Job Complete",
    tpCopy: "Job Complete"
  }
}

export const ROUTABLE_OPTIONS: OptionBoolean[] = [
  { name: "Routable", value: true },
  { name: "Not-routable", value: false }
]

export const MAIN_TYPES: OptionNumber[] = [
  {
    value: 3290,
    name: "Hatchback"
  },
  {
    value: 3291,
    name: "Saloon"
  },
  {
    value: 3292,
    name: "Sports"
  },
  {
    value: 3293,
    name: "Estate"
  },
  {
    value: 3294,
    name: "4x4"
  },
  {
    value: 3295,
    name: "People-Carrier"
  }
]

export const SECONDARY_TYPES: OptionNumber[] = [
  {
    value: 3296,
    name: "Compact Van"
  },
  {
    value: 3297,
    name: "Transit Van"
  },
  {
    value: 3298,
    name: "LWB Transit"
  },
  {
    value: 3299,
    name: "Luton Van"
  },
  {
    value: 3300,
    name: "Kit Car"
  },
  {
    value: 3301,
    name: "Large Saloon"
  },
  {
    value: 3302,
    name: "Classic Car"
  },
  {
    value: 3303,
    name: "Ride on Lawn Mower"
  },
  {
    value: 3304,
    name: "Golf Buggy"
  },
  {
    value: 3305,
    name: "Tractor"
  }
]

export const HIDE_LISTING_FOR_SECONDS = 600

export const SORTING_OPTIONS = [
  "pickup_date_asc",
  "distance_asc",
  "distance_desc"
  // - not for MVP - AVDD-3753
  // "nearest",
  // "capacity_asc",
  // "capacity_desc"
]

export const BOOKING_TYPE_OPTIONS: OptionBoolean[] = [
  { name: "Business Customer", value: true },
  { name: "Private Customer", value: false }
]
