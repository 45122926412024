import { FLOOR_GROUND } from "~/constants/listing"

export default (): Listing => ({
  id: null,
  uuid: null,

  pickup: {
    address: {
      postcode: null,
      postcode_short: null,
      addressLine1: null,
      addressLine2: null,
      addressLine3: null,
      city: null,
      region: null,
      country: null,
      coordinates: {
        lat: null,
        lng: null
      },
      agglomeration: null,
      place: {}
    },
    propertyType: null,
    floor: FLOOR_GROUND,
    liftAvailable: null
  },

  delivery: {
    address: {
      postcode: null,
      postcode_short: null,
      addressLine1: null,
      addressLine2: null,
      addressLine3: null,
      city: null,
      region: null,
      country: null,
      coordinates: {
        lat: null,
        lng: null
      },
      agglomeration: null,
      place: {}
    },
    propertyType: null,
    floor: FLOOR_GROUND,
    liftAvailable: null
  },

  category_id: null,

  items: [],
  itemsInitial: true
})
