import { prettyStripHTML } from "~/scripts/useful-functions"

export function buildStructuredData(data: Page): StructuredDataList {
  const headContent: any[] = []

  const faqData = buildFAQStructuredData(data)

  if (faqData) headContent.push(faqData)

  let location = data.location?.locationIdentifier

  if (location) {
    location = location.charAt(0).toUpperCase() + location.slice(1)
  }

  if (data.frontendUrl && location) {
    if (data?.seo?.breadcrumbs.length) {
      headContent.push(buildBreadcrumbList(data.frontendUrl, data.seo.breadcrumbs))
    }
    headContent.push(buildDataset(location, data.frontendUrl))
  }
  const organizationSchema = buildOrganization()

  // Local Programmatic Page
  if (location) {
    if (location === "London") {
      headContent.push(buildLocalBusiness())
    }
    organizationSchema.areaServed = buildAreaServed(location)
    headContent.push(buildHasMap(data.frontendUrl, location))
  }
  headContent.push(organizationSchema)

  return headContent
}

export function buildFAQStructuredData(data: any): FaqPage | null {
  // Add the FAQ schema if the page contains a FAQ block
  if (data && data.hasOwnProperty("blocks") && data.blocks.length) {
    const faqBlock = data.blocks.filter(block => {
      for (const attribute of block.attributes) {
        if (attribute.name === "className" && attribute.value.includes("faq")) {
          return true
        }
      }
      return false
    })
    if (faqBlock.length) {
      return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: extractFaqs(faqBlock[0]).map((faq: Faq) => {
          return {
            "@type": "Question",
            name: faq.question,
            acceptedAnswer: {
              "@type": "Answer",
              text: JSON.parse(prettyStripHTML(JSON.stringify(faq.answer))) // forgive me, but this seems the easiest way to prevent nuxt replacing apostrophe with HTML entities
            }
          }
        })
      }
    }
  }
  return null
}

function extractFaqs(blockTree: Block) {
  const faqs: Faq[] = []
  function traverse(block: Block) {
    if (block.type === "AINOBLOCKS_ACCORDION_FAQ_BLOCK") {
      const questionObj = block.attributes.find(attr => attr.name === "question")
      const question = questionObj?.value
      const answerBlock = block.innerBlocks.find(
        (b: Block) => b.type === "CORE_HTML" || b.type === "CORE_PARAGRAPH"
      )
      const answer = answerBlock?.innerHtml

      if (question && answer) {
        faqs.push({
          question,
          answer
        })
      }
    }
    if (block.innerBlocks && block.innerBlocks.length > 0) {
      block.innerBlocks.forEach(traverse)
    }
  }
  traverse(blockTree)

  return faqs
}

export function buildBreadcrumbList(url: string, breadcrumbs: Breadcrumb[]): BreadCrumbList {
  const breadcrumbsTitle = breadcrumbs.length ? breadcrumbs[breadcrumbs.length - 1].text : ""
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "https://www.anyvan.com/",
          name: "Home"
        }
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": "https://www.anyvan.com/removals",
          name: "Removals"
        }
      },
      {
        "@type": "ListItem",
        position: 3,
        item: {
          "@id": url,
          name: breadcrumbsTitle
        }
      }
    ]
  }
}

export function buildDataset(location: string, url: string): DataSet {
  return {
    "@context": "https://schema.org",
    "@type": "Dataset",
    name: `${location} Removals Map`,
    description: `Map showing completed AnyVan removal jobs in the vicinity of ${location}`,
    url: url.replace(/\/$/, "") + "#locationmap",
    spatialCoverage: {
      "@type": "Place",
      name: location
    }
  }
}

export function buildHasMap(url: string, location: string): HasMap {
  return {
    "@context": "https://schema.org",
    "@type": "Map",
    url: url + "#locationmap",
    mapType: "VectorMap",
    description: `Interactive map showing AnyVan's service area and completed jobs in ${location}`,
    name: `AnyVan ${location} Service Area Map`
  }
}

export function buildAggregateRating(ratingData): AggregateRating {
  const ratings = ratingData.rating.split(", ").map(Number)
  const average = ratings.reduce((a, b) => a + b, 0) / Number(ratings.length)
  const reviewCount = ratings.length
  return {
    "@type": "AggregateRating",
    ratingValue: average.toFixed(1),
    reviewCount
  }
}

export function buildAddress(): StructuredAddress {
  return {
    "@type": "PostalAddress",
    streetAddress: "5th Floor, The Triangle, 5-17 Hammersmith Grove",
    addressLocality: "London",
    postalCode: "W6 0LG",
    addressCountry: "UK"
  }
}

export function buildOrganization(): Organisation {
  return {
    "@context": "https://www.schema.org",
    "@type": "Organization",
    "@id": "https://www.anyvan.com/",
    description: "Delivery, Removal, Courier and Storage Services in the UK and Europe",
    name: "AnyVan",
    url: "https://www.anyvan.com/",
    logo: "https://www.anyvan.com/images/cust/AnyVanLogo.svg",
    sameAs: [
      "https://www.facebook.com/AnyVanUK",
      "https://twitter.com/anyvan",
      "https://www.instagram.com/weareanyvan/",
      "https://www.linkedin.com/company/anyvan/"
    ]
  }
}

function buildLocalBusiness(): LocalBusiness {
  return {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "AnyVan",
    url: "https://www.anyvan.com/",
    address: {
      ...buildAddress()
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: "51.49447750066836",
      longitude: "-0.22642663068890526"
    },
    telephone: "+442038723050",
    openingHoursSpecification: buildOpeningHours()
  }
}

function buildOpeningHours(): StructuresOpeningHours[] {
  return [
    {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      opens: "08:00",
      closes: "21:00"
    },
    {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: ["Saturday", "Sunday"],
      opens: "08:00",
      closes: "18:00"
    }
  ]
}

export function buildAreaServed(location: string): AreaServed {
  return {
    "@type": "Place",
    name: location + " and surrounding areas"
  }
}

export function buildOffers(location: string): Offer[] {
  return [
    {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: "Home Removals",
        serviceType: "Moving Services",
        description: `Professional home removal services in ${location} and surrounding areas`,
        areaServed: buildAreaServed(location)
      }
    },
    {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: "Office Removals",
        serviceType: "Moving Services",
        description: `Professional office removal services in ${location} and surrounding areas`,
        areaServed: buildAreaServed(location)
      }
    },
    {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: "International Removals",
        serviceType: "Moving Services",
        description: `Comprehensive international moving services from ${location}`,
        areaServed: [
          {
            "@type": "Country",
            name: "United Kingdom"
          },
          {
            "@type": "Country",
            name: "France"
          },
          {
            "@type": "Country",
            name: "Germany"
          }
        ]
      }
    },
    {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: "Student Removals",
        serviceType: "Moving Services",
        description: `Affordable student removal services in ${location} and surrounding areas`,
        areaServed: buildAreaServed(location)
      }
    },
    {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: "Commercial Removals",
        serviceType: "Moving Services",
        description: `Professional commercial removal services in ${location} and surrounding areas`,
        areaServed: buildAreaServed(location)
      }
    },
    {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: "Storage",
        serviceType: "Storage Services",
        description: `Secure storage services in ${location} and surrounding areas`,
        areaServed: buildAreaServed(location)
      }
    }
  ]
}

export function buildReview(internalReviewData): Review[] {
  const dataIsValid = Object.values(internalReviewData).every(value => value !== null)
  let reviews: Review[] = []
  if (dataIsValid) {
    const nameList: string[] = internalReviewData.name.split(/,/g).map(str => str.trim())
    const ratingList: number[] = internalReviewData.rating.split(/,/g).map(parseFloat)
    const reviewList: string[] =
      internalReviewData.review.match(/"([^"])*"/g)?.map(str => str.trim()) ?? []

    reviews = reviewList.map((_, index) => {
      return {
        "@type": "Review",
        author: { "@type": "Person", name: nameList[index] },
        reviewRating: { "@type": "Rating", ratingValue: ratingList[index] },
        reviewBody: reviewList[index]
      }
    })
  }
  return reviews
}
