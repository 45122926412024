/* google cannot be detected */
/* eslint no-undef: 0 */
import Vue from "vue"
import VueI18n from "vue-i18n"
import { getV2UrlByCategoryId } from "~/services/public/Listing"
import { CATEGORY_V4_ID_HOUSE_MOVE } from "~/constants/category"
import { V2_FLOORS, V2_PROPERTY_TYPES } from "~/constants/listing"

export function isAnExcludedIsland(payload: ListingPayload) {
  // provided by nuxt-google-maps-module
  const google: any = (Vue as any).$google
  if (!google) {
    return false
  }

  let excluded = false

  const islands = [
    // UK & IRELAND ISLANDS
    new google.maps.LatLngBounds(
      new google.maps.LatLng(58.6689164398, -3.8548798561),
      new google.maps.LatLng(61.1059423887, -0.2080261707)
    ), // orkney and shetland islands
    new google.maps.LatLngBounds(
      new google.maps.LatLng(56.8277778684, -7.7497896127),
      new google.maps.LatLng(57.7648395509, -7.0137056283)
    ), // southern outer hebrides
    new google.maps.LatLngBounds(
      new google.maps.LatLng(57.7296620943, -7.3020967416),
      new google.maps.LatLng(58.5215184636, -6.0908540658)
    ), // northern outer hebrides
    new google.maps.LatLngBounds(
      new google.maps.LatLng(50.56386, -1.587506),
      new google.maps.LatLng(50.711707, -1.03613)
    ), // isle of wight box 1
    new google.maps.LatLngBounds(
      new google.maps.LatLng(50.714533, -1.477729),
      new google.maps.LatLng(50.750497, -1.092435)
    ), // isle of wight box 2
    new google.maps.LatLngBounds(
      new google.maps.LatLng(50.750497, -1.346923),
      new google.maps.LatLng(50.773299, -1.243583)
    ), // isle of wight box 3
    // SPAIN ISLANDS
    new google.maps.LatLngBounds(
      new google.maps.LatLng(28.0, -16.9),
      new google.maps.LatLng(28.6, -16.1)
    ), // Tenerife
    new google.maps.LatLngBounds(
      new google.maps.LatLng(28.0, -14.5),
      new google.maps.LatLng(28.8, -13.8)
    ), // Fuerteventura
    new google.maps.LatLngBounds(
      new google.maps.LatLng(27.7, -15.8),
      new google.maps.LatLng(28.2, -15.3)
    ), // Gran Canaria
    new google.maps.LatLngBounds(
      new google.maps.LatLng(28.8, -13.9),
      new google.maps.LatLng(29.3, -13.4)
    ), // Lanzarote
    new google.maps.LatLngBounds(
      new google.maps.LatLng(28.4, -18.0),
      new google.maps.LatLng(28.9, -17.7)
    ), // La Palma
    new google.maps.LatLngBounds(
      new google.maps.LatLng(28.0, -17.4),
      new google.maps.LatLng(28.2, -17.1)
    ), // La Gomera
    new google.maps.LatLngBounds(
      new google.maps.LatLng(27.6, -18.2),
      new google.maps.LatLng(27.9, -17.9)
    ), // El Hierro
    new google.maps.LatLngBounds(
      new google.maps.LatLng(39.3, 2.3),
      new google.maps.LatLng(39.95, 3.5)
    ), // Mallorca
    new google.maps.LatLngBounds(
      new google.maps.LatLng(39.8, 3.8),
      new google.maps.LatLng(40.1, 4.3)
    ), // Menorca
    new google.maps.LatLngBounds(
      new google.maps.LatLng(38.8, 1.2),
      new google.maps.LatLng(39.1, 1.6)
    ), // Ibiza
    new google.maps.LatLngBounds(
      new google.maps.LatLng(38.6, 1.3),
      new google.maps.LatLng(38.8, 1.6)
    ), // Formentera
    new google.maps.LatLngBounds(
      new google.maps.LatLng(35.87, -5.36),
      new google.maps.LatLng(35.92, -5.28)
    ), // Ceuta
    new google.maps.LatLngBounds(
      new google.maps.LatLng(35.26, -2.96),
      new google.maps.LatLng(35.32, -2.92)
    ) // Melilla
  ]

  for (let i = 0; i < islands.length; i++) {
    if (
      (payload.pickup_address_data?.latitude &&
        payload.pickup_address_data?.longitude &&
        islands[i].contains(
          new google.maps.LatLng(
            payload.pickup_address_data?.latitude,
            payload.pickup_address_data?.longitude
          )
        )) ||
      (payload.delivery_address_data?.latitude &&
        payload.delivery_address_data?.longitude &&
        islands[i].contains(
          new google.maps.LatLng(
            payload.delivery_address_data?.latitude,
            payload.delivery_address_data?.longitude
          )
        ))
    ) {
      excluded = true
    }
  }

  return excluded
}

const Iso3166ToLocaleMap = {
  /*
   * When adding new territories, add their ISO3166_1 Alpha 2 country code and page locale here.
   * Get page local from nuxt config
   * Get ISO3166_1 Alpha 2 empirically (console.log)
   * No other coding changes should be necessary.
   */
  GB: "en",
  ES: "es"
}

export function shouldUseV2(payload: ListingPayload, pageLocale: VueI18n.Locale): boolean {
  if (!payload) return true

  // locale (based on URL) is not supported
  if (!Object.values(Iso3166ToLocaleMap).includes(pageLocale)) return true

  if (!payload.pickup_address_data || !payload.delivery_address_data) return true

  if (isAnExcludedIsland(payload)) return true

  const pickupCountry = payload.pickup_address_data.ISO_3166_1_Alpha_2
  const deliveryCountry = payload.delivery_address_data.ISO_3166_1_Alpha_2

  if (!pickupCountry || !deliveryCountry) return true

  /* Pickup country, delivery country and page country much match to use instant pricing */
  if (
    Iso3166ToLocaleMap[pickupCountry] === Iso3166ToLocaleMap[deliveryCountry] &&
    Iso3166ToLocaleMap[pickupCountry] === pageLocale
  )
    return false

  return true
}

export function redirectToV2(payload: ListingPayload, v4Host: string = "") {
  const form = document.createElement("form")
  const inputFactory = ([name, value]) => {
    const el = document.createElement("input")
    el.setAttribute("type", "hidden")
    el.setAttribute("name", name)
    el.setAttribute("value", value || "")
    form.appendChild(el)
  }

  form.method = "POST"
  // Comment & Uncomment for local execution
  form.action = "/" + getV2UrlByCategoryId(payload.category_id)
  // form.action = v4Host + "/" + getV2UrlByCategoryId(payload.category_id)

  const attrs: [string, string | null][] = [
    ["from", payload.from || ""],
    ["to", payload.to || ""],
    ["category_id", String(payload.category_id)],
    ["p_latitude", String(payload.pickup_address_data?.latitude)],
    ["p_longitude", String(payload.pickup_address_data?.longitude)],
    ["d_latitude", String(payload.delivery_address_data?.latitude)],
    ["d_longitude", String(payload.delivery_address_data?.longitude)],
    ["p_formatted_address", String(payload.from)],
    ["d_formatted_address", String(payload.to)],
    ["quote_from", String(payload.from)],
    ["quote_to", String(payload.to)],
    ["force_send_goods", "1"]
  ]
  if (payload.category_id === CATEGORY_V4_ID_HOUSE_MOVE) {
    attrs.push(
      ["origin_type_of_property", V2_PROPERTY_TYPES[String(payload.origin_type_of_property)]],
      [
        "destination_type_of_property",
        V2_PROPERTY_TYPES[String(payload.destination_type_of_property)]
      ],
      ["origin_property_level", V2_FLOORS[String(payload.origin_property_level)]],
      ["destination_property_level", V2_FLOORS[String(payload.destination_property_level)]]
    )
  }
  attrs.forEach(inputFactory)
  document.body.appendChild(form)
  form.submit()
}
