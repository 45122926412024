
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"
import { extractHref, getInnerText, matchProtocolAndHost } from "~/scripts/useful-functions"

export default Vue.extend({
  name: "PanelCTAs",
  props: {
    block: {
      type: Object as () => Block,
      required: true
    }
  },
  data() {
    return {
      formSubmitted: false
    }
  },
  computed: {
    ...mapGetters("phone-number", {
      getPhoneNumberValue: GET_PHONE_NUMBER_VALUE
    }),
    getButtonHref() {
      const button = this.block.innerBlocks[0]
      const href = extractHref(button.innerBlocks[0].innerHtml) || ""
      return href.replace(matchProtocolAndHost, this.$config.v4Host)
    },
    getButtonText() {
      const button = this.block.innerBlocks[0]
      return getInnerText(button.innerBlocks[0].innerHtml)
    }
  },
  methods: {
    submitForm() {
      this.$emit("submit-form")
    },
    handleCallUsClick() {
      this.$snowplow.trackCallUs({
        phoneNumber: this.getPhoneNumberValue.baseRaw,
        label: "panel_cta_button",
        action: "click",
        extension: this.getPhoneNumberValue.extension
      })
      window.location.href = `tel:${this.getPhoneNumberValue.base}`
    }
  }
})
