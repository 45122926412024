import { render, staticRenderFns } from "./TheRebrandHeader.vue?vue&type=template&id=75f62bb1"
import script from "./TheRebrandHeader.vue?vue&type=script&lang=ts"
export * from "./TheRebrandHeader.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginForm: require('/app/components/LoginForm.vue').default,AnyVanLogo: require('/app/components/AnyVanLogo.vue').default,NavOurServicesEn: require('/app/components/rebrand-header/NavOurServicesEn.vue').default,NavHelpCentreEn: require('/app/components/rebrand-header/NavHelpCentreEn.vue').default,NavOurServicesEs: require('/app/components/rebrand-header/NavOurServicesEs.vue').default,NavCallUs: require('/app/components/rebrand-header/NavCallUs.vue').default,NavLoginButton: require('/app/components/rebrand-header/NavLoginButton.vue').default,Icon: require('/app/components/vantone/Icon.vue').default})
